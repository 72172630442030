<template>
  <div class="d-flex mt-3 justify-content-center">
    <div class="card shadow bg-light">
      <div class="card-body text-center">
        <img v-if="!altProfileImage" :src="imagenPerfil" @error="altProfileImage = true" class="card-img-top img-fluid rounded-circle" style="max-width: 200px">
        <img v-else src="../assets/images/user.png" class="card-img-top img-fluid rounded-circle" style="max-width: 200px">
        <h1 class="mt-4 mb-4">{{ perfil.usuario }}</h1>

        <table class="table table-bordered table-responsive mt-4 text-left">
          <tr class="thead-light">
            <th>Feha de registro</th>
            <td>{{ new Date(perfil.fechaRegistro).toLocaleDateString() }}</td>
          </tr>

          <tr class="thead-light">
            <th>Nombre</th>
            <td>{{ perfil.nombreCompleto }}</td>
          </tr>

          <tr class="thead-light">
            <th>Correo</th>
            <td v-show="!showEditarCorreo">
              {{ perfil.correo }} <button type="button" @click="showEditarCorreo = !showEditarCorreo" class="btn m-1 btn-outline-info" title="Editar"><i class="fas fa-edit"></i></button>
            </td>

            <td v-show="showEditarCorreo">
              <input type="text" v-model="perfil.correo" @keyup.enter="editarCorreo" class="form-control">

              <LoadingButton @clicked="editarCorreo()" :iconProp="'fas fa-check'" :loadingProp="loadingEditandoCorreo" :classProp="'btn btn-outline-success'"></LoadingButton>
              <button type="button" @click="$router.go()" class="btn m-1 btn-outline-danger" title="Cancelar"><i class="fas fa-times"></i></button>
            </td>
          </tr>

          <tr class="thead-light">
            <th>Invitado por</th>
            <td>{{ perfil.invitadoPor }}</td>
          </tr>

          <tr class="thead-light">
            <th>Días restantes</th>

            <td v-if="perfil.diasRestantes > 1000"><span class="badge badge-success">Vitalicio</span></td>

            <td v-else>{{ perfil.diasRestantes }}</td>

          </tr>
        </table>

        <button type="button" @click="showCambiarPasswd = !showCambiarPasswd" class="btn m-1 btn-primary" title="Cambiar contraseña"><i class="fas fa-key"></i> Cambiar contraseña</button>

        <div v-show="showCambiarPasswd" class="m-2 text-left">
          <h5>Nueva contraseña</h5>
          <input type="password" v-model="passwd" class="form-control">
          <h5>Repetir contraseña</h5>
          <input type="password" v-model="passwd2" class="form-control">

          <div class="text-center">
            <LoadingButton @clicked="cambiarPasswd()" :iconProp="'fas fa-check'" :loadingProp="loadingCambiandoPasswd" :classProp="'btn btn-outline-success'"></LoadingButton>
            <button type="button" @click="showCambiarPasswd = !showCambiarPasswd" class="btn m-1 btn-outline-danger" title="Cancelar"><i class="fas fa-times"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import config from '../config'
  import Swal from 'sweetalert2'
  import LoadingButton from '../components/LoadingButton.vue'

  export default {
    data() {
      return {
        showEditarCorreo: false,
        showCambiarPasswd: false,
        loadingEditandoCorreo: false,
        loadingCambiandoPasswd: false,
        passwd: null,
        passwd2: null,
        altProfileImage: false
      }
    },
    computed: {
      perfil() {
        return this.$store.state.perfil
      },
      imagenPerfil() {
        return `https://bemag.app/Users/${this.perfil.embyId}/Images/Primary?width=200`
      }
    },
    components: {
      LoadingButton
    },
    methods: {
      async editarCorreo() {
        try {
          if (!this.perfil.correo) {
            Swal.fire({
              title: 'Error',
              text: 'Tienes que poner un correo',
              icon: 'error',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true
            })

            return
          }

          this.loadingEditandoCorreo = true

          const res = await axios.post(config.apiUrl + '/usuarios/perfil', {
            embyId: this.perfil.embyId,
            correo: this.perfil.correo
          })

          this.loadingEditandoCorreo = false
          this.showEditarCorreo = false

          Swal.fire({
            title: 'Bien',
            text: res.data,
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        } catch (e) {
          this.loadingEditandoCorreo = false

          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      },
      async cambiarPasswd() {
        try {
          if (!this.passwd) {
            Swal.fire({
              title: 'Error',
              text: 'Tienes que poner una contraseña',
              icon: 'error',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true
            })

            return
          }

          if (this.passwd !== this.passwd2) {
            Swal.fire({
              title: 'Error',
              text: 'Las contraseñas no coinciden',
              icon: 'error',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true
            })

            return
          }

          this.loadingCambiandoPasswd = true

          const res = await axios.post(config.apiUrl + '/usuarios/cambiarpasswd', {
            embyId: this.perfil.embyId,
            passwd: this.passwd
          })

          this.loadingCambiandoPasswd = false
          this.showCambiarPasswd = false

          Swal.fire({
            title: 'Bien',
            text: res.data,
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })

        } catch (e) {
          this.loadingCambiandoPasswd = false

          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      }
    },
    mounted() {
      this.$store.commit('setPanelLayout')
    }
  }
</script>
